export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

export const FORM_SUBMISSION_API_ENDPOINT =
  process.env.REACT_APP_FORM_SUBMISSION_ENDPOINT;

// Page Navigation (Stepper)
export const UPDATE_PAGE_NAVIGATION = "UPDATE_PAGE_NAVIGATION";
export const SET_ACTIVE_STEP = "SET_ACTIVE_STEP";
export const SET_COMPLETED_STEP = "SET_COMPLETED_STEP";

// AgentInfo
export const UPDATE_PERSONAL_INFO = "UPDATE_PERSONAL_INFO";
export const CLEAR_PERSONAL_INFO = "CLEAR_PERSONAL_INFO";

// ApplicantInfo
export const UPDATE_APPLICANT_INFO = "DATE_APPLICANT_INFO";
export const CLEAR_APPLICANT_INFO = "CLEAR_APPLICANT_INFO";

// Policy Name Change
export const POLICY_NAME_CHANGE_SUCCESS = "POLICY_NAME_CHANGE_SUCCESS";
export const POLICY_NAME_CHANGE_FAILURE = "POLICY_NAME_CHANGE_FAILURE";

export const EntityTypes = {
  CORPORATION: "Corporation",
  INDIVIDUAL: "Individual",
  JOIN_VENTURE: "Joint Venture",
  LLC: "LLC",
};

export const FormTypes = {
  AGENCY_ACCORD_REQUEST: "agency_acord_request",
  AGENT_USER_LINKING_REQUEST: "agent_user_linking_request",
  POLICY_NAME_CHANGE_REQUEST: "policy_name_change_request",
  POLICY_ADDRESS_CHANGE_REQUEST: "policy_address_change_request",
  POLICY_DOCUMENT_REQUEST: "policy_document_request",
  POLICY_MAILING_ADDRESS_CHANGE_REQUEST:
    "policy_mailing_address_change_request",
};

export const states = [
  {
    name: "Alabama",
    abbreviation: "AL",
  },
  {
    name: "Alaska",
    abbreviation: "AK",
  },
  {
    name: "Arizona",
    abbreviation: "AZ",
  },
  {
    name: "Arkansas",
    abbreviation: "AR",
  },
  {
    name: "California",
    abbreviation: "CA",
  },
  {
    name: "Colorado",
    abbreviation: "CO",
  },
  {
    name: "Connecticut",
    abbreviation: "CT",
  },
  {
    name: "Deleware",
    abbreviation: "DE",
  },
  {
    name: "Florida",
    abbreviation: "FL",
  },
  {
    name: "Georgia",
    abbreviation: "GA",
  },
  {
    name: "Hawaii",
    abbreviation: "HI",
  },
  {
    name: "Idaho",
    abbreviation: "ID",
  },
  {
    name: "Illinois",
    abbreviation: "IL",
  },
  {
    name: "Indiana",
    abbreviation: "IN",
  },
  {
    name: "Iowa",
    abbreviation: "IA",
  },
  {
    name: "Kansas",
    abbreviation: "KS",
  },
  {
    name: "Kentucky",
    abbreviation: "KY",
  },
  {
    name: "Louisiana",
    abbreviation: "LA",
  },
  {
    name: "Maine",
    abbreviation: "ME",
  },
  {
    name: "Maryland",
    abbreviation: "MD",
  },
  {
    name: "Massachusetts",
    abbreviation: "MA",
  },
  {
    name: "Michigan",
    abbreviation: "MI",
  },
  {
    name: "Minnesota",
    abbreviation: "MN",
  },
  {
    name: "Mississippi",
    abbreviation: "MS",
  },
  {
    name: "Missouri",
    abbreviation: "MO",
  },
  {
    name: "Montana",
    abbreviation: "MT",
  },
  {
    name: "Nebraska",
    abbreviation: "NE",
  },
  {
    name: "Nevada",
    abbreviation: "NV",
  },
  {
    name: "New Hampshire",
    abbreviation: "NH",
  },
  {
    name: "New Jersey",
    abbreviation: "NJ",
  },
  {
    name: "New Mexico",
    abbreviation: "NM",
  },
  {
    name: "New York",
    abbreviation: "NY",
  },
  {
    name: "North Carolina",
    abbreviation: "NC",
  },
  {
    name: "North Dakota",
    abbreviation: "ND",
  },
  {
    name: "Ohio",
    abbreviation: "OH",
  },
  {
    name: "Oklahoma",
    abbreviation: "OK",
  },
  {
    name: "Oregon",
    abbreviation: "OR",
  },
  {
    name: "Pennsylvania",
    abbreviation: "PA",
  },
  {
    name: "Rhode Island",
    abbreviation: "RI",
  },
  {
    name: "South Carolina",
    abbreviation: "SC",
  },
  {
    name: "South Dakota",
    abbreviation: "SD",
  },
  {
    name: "Tennessee",
    abbreviation: "TN",
  },
  {
    name: "Texas",
    abbreviation: "TX",
  },
  {
    name: "Utah",
    abbreviation: "UT",
  },
  {
    name: "Vermont",
    abbreviation: "VT",
  },
  {
    name: "Virginia",
    abbreviation: "VA",
  },
  {
    name: "Washington",
    abbreviation: "WA",
  },
  {
    name: "West Virginia",
    abbreviation: "WV",
  },
  {
    name: "Wisconsin",
    abbreviation: "WI",
  },
  {
    name: "Wyoming",
    abbreviation: "WY",
  },
];

