import InputMask from 'react-input-mask';
import React from 'react';
import Form from 'react-bootstrap/Form';
import { states } from '../../actions/types';
import { Field, ErrorMessage } from 'formik';

const PolicyDocumentRequestFormComponent = ({ props }) => {
  return (
    <>
      <Form.Group controlId='document_request.request_type'>
        <Form.Label>Request Type</Form.Label>
        <Field
          as='select'
          name='document_request.request_type'
          className='form-control'>
          <option value='none'>Choose...</option>
          <option value='Evidence of Insurance'>Evidence of Insurance</option>
          <option value='Declaration Page'>Declaration Page</option>
          <option value='Complete Policy'>Complete Policy</option>
        </Field>
        <div className='validation-error'>
          <ErrorMessage name='document_request.request_type' />
        </div>
      </Form.Group>
      <Form.Group controlId='document_request.contact_method'>
        <Form.Label>How would you like us to send it?</Form.Label>
        <Field
          as='select'
          name='document_request.contact_method'
          className='form-control'>
          <option value='none'>Choose...</option>
          <option value='Email'>Email</option>
          <option value='Fax'>Fax</option>
          <option value='Mail'>First Class Mail</option>
        </Field>
        <div className='validation-error'>
          <ErrorMessage name='document_request.contact_method' />
        </div>
      </Form.Group>
      {props.values.document_request.contact_method === 'Mail' && (
        <>
          <Form.Group controlId='document_request.company'>
            <Form.Label>Company</Form.Label>
            <Field name='document_request.company' className='form-control' />
            <div className='validation-error'>
              <ErrorMessage name='document_request.company' />
            </div>
          </Form.Group>
          <Form.Group controlId='document_request.contact_name'>
            <Form.Label>Contact</Form.Label>
            <Field name='document_request.contact_name' className='form-control' />
            <div className='validation-error'>
              <ErrorMessage name='document_request.contact_name' />
            </div>
          </Form.Group>
          <Form.Group controlId='document_request.mailing_address_street'>
            <Form.Label>Street Address</Form.Label>
            <Field
              name='document_request.mailing_address_street'
              className='form-control'
            />
            <div className='validation-error'>
              <ErrorMessage name='document_request.mailing_address_street' />
            </div>
          </Form.Group>
          <Form.Group controlId='document_request.mailing_address_street_2'>
            <Form.Label>Street Address 2</Form.Label>
            <Field
              name='document_request.mailing_address_street_2'
              className='form-control'
            />
            <div className='validation-error'>
              <ErrorMessage name='document_request.mailing_address_street_2' />
            </div>
          </Form.Group>
          <Form.Group controlId='document_request.mailing_address_city'>
            <Form.Label>City</Form.Label>
            <Field
              name='document_request.mailing_address_city'
              className='form-control'
            />
            <div className='validation-error'>
              <ErrorMessage name='document_request.mailing_address_city' />
            </div>
          </Form.Group>
          <Form.Group controlId='document_request.mailing_address_state'>
            <Form.Label>State</Form.Label>
            <Field
              as='select'
              name='document_request.mailing_address_state'
              className='form-control'>
              <option value='none'>Choose...</option>
              {states.map((state, index) => (
                <option key={index}>{state.abbreviation}</option>
              ))}
            </Field>
            <div className='validation-error'>
              <ErrorMessage name='document_request.mailing_address_state' />
            </div>
          </Form.Group>
          <Form.Group controlId='document_request.mailing_address_zipcode'>
            <Form.Label>Zip Code</Form.Label>
            <Field
              name='document_request.mailing_address_zipcode'
              className='form-control'
            />
            <div className='validation-error'>
              <ErrorMessage name='document_request.mailing_address_zipcode' />
            </div>
          </Form.Group>
        </>
      )}
      {props.values.document_request.contact_method === 'Fax' && (
        <>
          <Form.Group controlId='document_request.contact_name'>
            <Form.Label>Contact</Form.Label>
            <Field name='document_request.contact_name' className='form-control' />
            <div className='validation-error'>
              <ErrorMessage name='document_request.contact_name' />
            </div>
          </Form.Group>
          <Form.Group controlId='document_request.fax_number'>
            <Form.Label>Fax</Form.Label>
            <InputMask
              name='document_request.fax_number'
              type='text'
              className='form-control'
              value={props.values.document_request.fax_number}
              onChange={e =>
                props.setFieldValue(
                  'document_request.fax_number',
                  e.target.value
                )
              }
              onBlur={props.onBlur}
              onError={props.onError}
              mask='999-999-9999'
              maskChar='_'
            />
            <div className='validation-error'>
              <ErrorMessage name='document_request.fax_number' />
            </div>
          </Form.Group>
        </>
      )}
      {props.values.document_request.contact_method === 'Email' && (
        <>
          <Form.Group controlId='document_request.contact_name'>
            <Form.Label>Contact</Form.Label>
            <Field name='document_request.contact_name' className='form-control' />
            <div className='validation-error'>
              <ErrorMessage name='document_request.contact_name' />
            </div>
          </Form.Group>
          <Form.Group controlId='document_request.contact_email'>
            <Form.Label>Email</Form.Label>
            <Field
              name='document_request.contact_email'
              className='form-control'
            />
            <div className='validation-error'>
              <ErrorMessage name='document_request.contact_email' />
            </div>
          </Form.Group>
        </>
      )}
    </>
  );
};

export default PolicyDocumentRequestFormComponent;
