import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// import Container from "react-bootstrap/Container";
import {
  List,
  ListItem,
  ListItemText,
  Link,
  Container
} from "@material-ui/core";

// const logo = require("./images/American-Modern.png");
// const logo = './images/American-Modern.png';

const Footer = () => {
  const useStyles = makeStyles(theme => ({
    facebook: {
      height: "20px",
      float: "left",
      display: "inline-block",
      position: "relative",
      background:
        "linear-gradient(to bottom, #1e5799 0%,#ffffff 1%,#eeeeee 45%,#eeeeee 100%)",
      filter:
        "progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e5799', endColorstr='#eeeeee',GradientType=0 )",
      border: "1px solid #ccc",
      borderRadius: "3px",
      WebkitBorderRadius: "3px",
      MozBorderRadius: "3px",
      paddingTop: "0px !important",
      padding: "0px 4px 2px 4px"
    },
    twitter: {
      width: "80px",
      float: "left",
      display: "inline-block"
    },
    list: {
      float: "left",
      width: "21%",
      paddingTop: "40px",
      paddingBottom: "30px"
    },
    listItem: {
      padding: "0px",
      marginTop: "-10px",
      marginBottom: "-10px"
    },
    listText: {
      fontSize: "12px",
      fontFamily: "'Raleway', 'Open Sans', sans-serif",
      fontWeight: "bold",
      color: "#432F87 !important"
    },
    listTextHeader: {
      fontSize: "12px",
      fontWeight: "bold",
      color: "#54585a !important"
    },
    terms: {
      float: "left",
      // paddingRight: "10%",
      fontFamily: "'Raleway', 'Open Sans', sans-serif",
      fontSize: "12px",
      fontWeight: "400",
      color: "#747474",
      lineHeight: "15px"
    },
    address: {
      float: "left",
      // paddingRight: "10%",
      fontFamily: "'Raleway', 'Open Sans', sans-serif",
      fontSize: "12px",
      fontWeight: "400",
      color: "#333",
      lineHeight: "15px",
      paddingTop: "15px"
    },
    copyright: {
      float: "left",
      // paddingRight: "10%",
      fontFamily: "'Raleway', 'Open Sans', sans-serif",
      fontSize: "12px",
      fontWeight: "400",
      color: "#333",
      lineHeight: "15px",
      paddingTop: "15px",
      paddingBottom: "100px"
    }
  }));

  const classes = useStyles();

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "4em",
          background: "#54575a",
          padding: "1em 1.25em",
          margin: "0 auto",
          marginTop: "50px"
        }}
      >
        <Container
          style={{
            maxWidth: "960px"
          }}
        >
          <div className={classes.twitter}>
            <Link
              href="https://twitter.com/share?ref_src=twsrc%5Etfw"
              className={"twitter-share-button"}
              data-show-count={"false"}
              data-via="Americanmodins"
              data-hashtags="Americanmodins"
            >
              Tweet
            </Link>
          </div>
          <div className={classes.facebook}>
            <iframe
              style={{
                border: "none",
                visibility: "visible",
                width: "49px",
                verticalAlign: "text-top"
              }}
              name="f3c8f870ced7598"
              data-testid="fb:share_button Facebook Social Plugin"
              title="fb:share_button Facebook Social Plugin"
              frameBorder="0"
              allowtransparency="true"
              allowFullScreen={true}
              scrolling="no"
              allow="encrypted-media"
              src="https://www.facebook.com/plugins/share_button.php?app_id=&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fconnect%2Fxd_arbiter.php%3Fversion%3D46%23cb%3Df37bce3b7e3d6b8%26domain%3Dwww.amig.com%26origin%3Dhttps%253A%252F%252Fwww.amig.com%252Ffd45c306ee9bf%26relation%3Dparent.parent&amp;container_width=80&amp;href=https%3A%2F%2Ffacebook.com%2FAmericanModern%2F&amp;locale=en_US&amp;width=66px"
            ></iframe>
          </div>
        </Container>
      </div>
      <div style={{ maxWidth: "960px", margin: "0 auto" }}>
        <Container>
          <List className={classes.list}>
            <ListItem className={classes.listItem}>
              <ListItemText>
                <Link
                  href="https://www.amig.com"
                  style={{ textDecorationLine: "none" }}
                >
                  <span className={classes.listTextHeader}>Home</span>
                </Link>
              </ListItemText>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText>
                <Link
                  href="https://www.amig.com/customer/"
                  style={{ textDecorationLine: "none" }}
                >
                  <span className={classes.listTextHeader}>
                    Customer center
                  </span>
                </Link>
              </ListItemText>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText>
                <Link
                  href="https://www.amig.com/customer/make-a-payment/"
                  style={{ textDecorationLine: "none" }}
                >
                  <span className={classes.listText}>Make a payment</span>
                </Link>
              </ListItemText>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText>
                <Link
                  href="https://www.amig.com/customer/file-a-claim/"
                  style={{ textDecorationLine: "none" }}
                >
                  <span className={classes.listText}>File a claim</span>
                </Link>
              </ListItemText>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText>
                <Link
                  href="https://www.amig.com/customer/change-your-policy/"
                  style={{ textDecorationLine: "none" }}
                >
                  <span className={classes.listText}>Change your policy</span>
                </Link>
              </ListItemText>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText>
                <Link
                  href="https://www.amig.com/customer/manage-your-account/"
                  style={{ textDecorationLine: "none" }}
                >
                  <span className={classes.listText}>Manage your account</span>
                </Link>
              </ListItemText>
            </ListItem>
          </List>

          <List className={classes.list}>
            <ListItem className={classes.listItem}>
              <ListItemText>
                <span className={classes.listText}>&nbsp;</span>
              </ListItemText>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText>
                <Link
                  href="https://www.amig.com/insurance/"
                  style={{ textDecorationLine: "none" }}
                >
                  <span className={classes.listTextHeader}>
                    Insurance solutions
                  </span>
                </Link>
              </ListItemText>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText>
                <Link
                  href="https://www.amig.com/insurance/landlord-property/"
                  style={{ textDecorationLine: "none" }}
                >
                  <span className={classes.listText}>Landlord property</span>
                </Link>
              </ListItemText>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText>
                <Link
                  href="https://www.amig.com/insurance/vacation-home/"
                  style={{ textDecorationLine: "none" }}
                >
                  <span className={classes.listText}>Vacation home</span>
                </Link>
              </ListItemText>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText>
                <Link
                  href="https://www.amig.com/insurance/vacant-property/"
                  style={{ textDecorationLine: "none" }}
                >
                  <span className={classes.listText}>Vacant property</span>
                </Link>
              </ListItemText>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText>
                <Link
                  href="https://www.amig.com/insurance/full-time-home/"
                  style={{ textDecorationLine: "none" }}
                >
                  <span className={classes.listText}>Full-time home</span>
                </Link>
              </ListItemText>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText>
                <Link
                  href="https://www.amig.com/insurance/mobile-home/"
                  style={{ textDecorationLine: "none" }}
                >
                  <span className={classes.listText}>Mobile home</span>
                </Link>
              </ListItemText>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText>
                <Link
                  href="https://www.amig.com/insurance/collector-car/"
                  style={{ textDecorationLine: "none" }}
                >
                  <span className={classes.listText}>Collector car</span>
                </Link>
              </ListItemText>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText>
                <Link
                  href="https://www.amig.com/insurance/boat/"
                  style={{ textDecorationLine: "none" }}
                >
                  <span className={classes.listText}>Boat</span>
                </Link>
              </ListItemText>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText>
                <Link
                  href="https://www.amig.com/insurance/yacht/"
                  style={{ textDecorationLine: "none" }}
                >
                  <span className={classes.listText}>Yacht</span>
                </Link>
              </ListItemText>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText>
                <Link
                  href="https://www.amig.com/insurance/personal-watercraft/"
                  style={{ textDecorationLine: "none" }}
                >
                  <span className={classes.listText}>Personal watercraft</span>
                </Link>
              </ListItemText>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText>
                <Link
                  href="https://www.amig.com/insurance/motorcycle"
                  style={{ textDecorationLine: "none" }}
                >
                  <span className={classes.listText}>Motorcycle</span>
                </Link>
              </ListItemText>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText>
                <Link
                  href="https://www.amig.com/insurance/all-terrain-vehicle/"
                  style={{ textDecorationLine: "none" }}
                >
                  <span className={classes.listText}>All-terrain vehicle</span>
                </Link>
              </ListItemText>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText>
                <Link
                  href="https://www.amig.com/insurance/golf-cart/"
                  style={{ textDecorationLine: "none" }}
                >
                  <span className={classes.listText}>Golf cart</span>
                </Link>
              </ListItemText>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText>
                <Link
                  href="https://www.amig.com/insurance/snowmobile"
                  style={{ textDecorationLine: "none" }}
                >
                  <span className={classes.listText}>Snowmobile</span>
                </Link>
              </ListItemText>
            </ListItem>
          </List>

          <List className={classes.list}>
            <ListItem className={classes.listItem}>
              <ListItemText>
                <span className={classes.listText}>&nbsp;</span>
              </ListItemText>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText>
                <Link
                  href="https://www.amig.com/company/"
                  style={{ textDecorationLine: "none" }}
                >
                  <span className={classes.listTextHeader}>
                    Company overview
                  </span>
                </Link>
              </ListItemText>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText>
                <Link
                  href="https://www.amig.com/compan/news/"
                  style={{ textDecorationLine: "none" }}
                >
                  <span className={classes.listText}>Newsroom</span>
                </Link>
              </ListItemText>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText>
                <Link
                  href="https://www.amig.com/company/news/media-resources-and-contact/"
                  style={{ textDecorationLine: "none" }}
                >
                  <span className={classes.listText}>Media resources</span>
                </Link>
              </ListItemText>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText>
                <Link
                  href="https://www.amig.com/company/management/"
                  style={{ textDecorationLine: "none" }}
                >
                  <span className={classes.listText}>Management team</span>
                </Link>
              </ListItemText>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText>
                <Link
                  href="https://www.amig.com/company/careers/"
                  style={{ textDecorationLine: "none" }}
                >
                  <span className={classes.listText}>Careers</span>
                </Link>
              </ListItemText>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText>
                <Link
                  href="https://www.amig.com/company/company-values"
                  style={{ textDecorationLine: "none" }}
                >
                  <span className={classes.listText}>Company values</span>
                </Link>
              </ListItemText>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText>
                <Link
                  href="https://www.amig.com/company/company-history"
                  style={{ textDecorationLine: "none" }}
                >
                  <span className={classes.listText}>Company history</span>
                </Link>
              </ListItemText>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText>
                <Link
                  href="https://www.amig.com/company/company-directions"
                  style={{ textDecorationLine: "none" }}
                >
                  <span className={classes.listText}>Campus directions</span>
                </Link>
              </ListItemText>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText>
                <Link
                  href="https://www.amig.com/company/contact-us"
                  style={{ textDecorationLine: "none" }}
                >
                  <span className={classes.listText}>Contact us</span>
                </Link>
              </ListItemText>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText>
                <Link
                  href="http://siservices.com"
                  style={{ textDecorationLine: "none" }}
                >
                  <span className={classes.listText}>Adjuster training</span>
                </Link>
              </ListItemText>
            </ListItem>
          </List>
        </Container>

        <Container
          style={{
            float: "left",
            paddingRight: "10%",
            background:
              "url(https://www.amig.com/amig-wp/wp-content/themes/amig2015/assets/images/ftr-brdr.png) repeat-x top left",
            paddingTop: "30px"
          }}
        ></Container>

        <Container className={classes.terms}>
          Coverage is subject to policy terms, conditions, limitations,
          exclusions, underwriting review and approval, and may not be available
          for all risks or in all states. Rates and discounts vary, are
          determined by many factors and are subject to change. Policies are
          written by one of the licensed insurers of American Modern Insurance
          Group, Inc., including American Modern Home Insurance Company d/b/a in
          CA American Modern Insurance Company (Lic. No 2222-8).
        </Container>

        <Container className={classes.address}>
          Corporate office | 7000 Midland Blvd. | Amelia, OH 45102 |{" "}
          <Link
            href="mailto:webinfo@amig.com"
            style={{ textDecorationLine: "none" }}
          >
            webinfo@amig.com
          </Link>
          <br />
          Mailing address | PO Box 5323 | Cincinnati, OH 45201-5323
        </Container>

        <Container className={classes.copyright}>
          © 2020 American Modern Insurance Group, Inc. All rights reserved.
          <br />
          <Link
            href="https://amig.com/company/legal/internet-privacy-policy/"
            style={{ textDecorationLine: "none" }}
          >
            <span style={{ color: "#432F87", fontWeight: "600" }}>
              Privacy policy
            </span>
          </Link>{" "}
          |{" "}
          <Link
            href="https://amig.com/company/legal/legal-terms/"
            style={{ textDecorationLine: "none" }}
          >
            <span style={{ color: "#432F87", fontWeight: "600" }}>
              Legal disclaimers
            </span>
          </Link>{" "}
          |{" "}
          <Link
            href="https://amig.com/company/legal/privacy-notice/"
            style={{ textDecorationLine: "none" }}
          >
            <span style={{ color: "#432F87", fontWeight: "600" }}>
              Notice of information practices
            </span>
          </Link>
        </Container>
      </div>
      {/* <div id={"webchat"} role={"main"}></div> */}
    </>
  );
};

export default Footer;
