import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { states } from "../../actions/types";
import InputMask from "react-input-mask";
import sendRequest from "../../actions/sendRequest";
import { setAlert } from "../../actions/alert";
import { FormTypes } from "../../actions/types";
import {
  Formik,
  Field,
  ErrorMessage,
  FieldArray,
} from "formik";
import { agencyAcordRequestSchema } from "../../validation/schemas";

const AgencyAcordRequestForm = ({ sendRequest, setAlert }) => {
  const initialValues = {
    amig_agency_code: "",
    agency_name: "",
    street: "",
    street_2: "",
    city: "",
    state: "",
    zipcode: "",
    phone: "",
    phone_ext: "",
    email: "",
    general_agent_name: "",
    general_agent_code: "",
    download_contact_name: "",
    download_contact_phone: "",
    download_contact_phone_ext: "",
    download_contact_email: "",
    software: "",
    software_version: "",
    have_IVANS: "",
    y_account: "",
    batch_id: "",
    machine_address: "",
    commission_agency_codes: [
      {
        agency_code: "",
      },
    ],
    authorized: "",
    checked: {
      policy_dl: "",
      comm_reporting: "",
    },
  };

  const handleSubmit = async (values) => {
    window.scrollTo(0, 0);

    const acord_request = { ...values };

    if (acord_request.phone_ext) {
      acord_request.phone = `${acord_request.phone} ext: ${acord_request.phone_ext}`;
      delete acord_request.phone_ext;
    }

    if (acord_request.download_contact_phone_ext) {
      acord_request.download_contact_phone = `${acord_request.download_contact_phone} ext: ${acord_request.download_contact_phone_ext}`;
      delete acord_request.download_contact_phone_ext;
    }

    try {
      const response = await sendRequest(
        { acord_request },
        FormTypes.AGENCY_ACCORD_REQUEST
      );
      response.status >= 200 &&
        response.status < 300 &&
        setAlert(
          "Your agency ACORD AL3 request has been submitted. Please check your email for confirmation.",
          "success",
          20000
        );
    } catch (error) {
      setAlert(
        "An error occured and we were unable to receive your agency acord request.",
        "danger",
        20000
      );
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={agencyAcordRequestSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      {(props) => (
        <>
          <Row>
            <Col sm={12} md={{ span: 8, offset: 2 }}>
              <div>
                <p className="display-4 text-center text-primary">
                  Agency ACORD AL3 Request
                </p>
              </div>
              <div className="small bg-light p-2">
                <p>
                  Instructions for submitting this form to the American Modern
                  Insurance Group:
                </p>
                <p>
                  This agency policy download request form is for ACORD AL3
                  Compliant Agency Management Systems{" "}
                </p>
                <p>
                  Once we have received this completed form, and have verified
                  your agency codes and IVANS information, it will take five
                  business days for us to add your agency to our IVANS batch
                  files. If you have questions or need assistance, contact us:{" "}
                  <a href="mailto:systemsupport@amig.com">
                    systemsupport@amig.com
                  </a>{" "}
                  or 866-527-9583
                </p>
              </div>
              <Form
                onSubmit={props.handleSubmit}
                className="small font-weight-bold"
              >
                <div className="bg-secondary text-white text-center p-1">
                  <h3>Your Agency</h3>
                </div>
                <p className="font-weight-light bg-light p-2">
                  To set up your account, tell us about your agency.
                </p>
                <Form.Group controlId="amig_agency_code">
                  <Form.Label>American Modern Agency Code</Form.Label>
                  <Field name="amig_agency_code" className="form-control" />
                  <div className="validation-error">
                    <ErrorMessage name="amig_agency_code" />
                  </div>
                </Form.Group>
                <Form.Group controlId="agency_name">
                  <Form.Label>Your Agency Name</Form.Label>
                  <Field name="agency_name" className="form-control" />
                  <div className="validation-error">
                    <ErrorMessage name="agency_name" />
                  </div>
                </Form.Group>
                <Form.Group controlId="street">
                  <Form.Label>Street Address</Form.Label>
                  <Field name="street" className="form-control" />
                  <div className="validation-error">
                    <ErrorMessage name="street" />
                  </div>
                </Form.Group>
                <Form.Group controlId="street_2">
                  <Form.Label>Street Address 2</Form.Label>
                  <Field name="street_2" className="form-control" />
                  <div className="validation-error">
                    <ErrorMessage name="street_2" />
                  </div>
                </Form.Group>
                <Form.Group controlId="city">
                  <Form.Label>City</Form.Label>
                  <Field name="city" className="form-control" />
                  <div className="validation-error">
                    <ErrorMessage name="city" />
                  </div>
                </Form.Group>
                <Form.Group controlId="state">
                  <Form.Label>State</Form.Label>
                  <Field as="select" name="state" className="form-control">
                    <option value="none">Choose...</option>
                    {states.map((state, index) => (
                      <option key={index}>{state.abbreviation}</option>
                    ))}
                  </Field>
                  <div className="validation-error">
                    <ErrorMessage name="state" />
                  </div>
                </Form.Group>
                <Form.Group controlId="zipcode">
                  <Form.Label>Zip Code</Form.Label>
                  {/* <NumericInput 
                className='form-control' 
                style={ false }
                name='zipcode'
                value={acord_request.zipcode}
                onChange={e => handleAcordRequestChange(e)}
                required
              /> */}
                  <Field
                    name="zipcode"
                    type="text"
                    as="input"
                    className="form-control"
                  />
                  <div className="validation-error">
                    <ErrorMessage name="zipcode" />
                  </div>
                </Form.Group>
                <Row>
                  <Col sm={9}>
                    <Form.Group controlId="phone">
                      <Form.Label>Agency Phone</Form.Label>
                      <InputMask
                        name="phone"
                        type="text"
                        className="form-control"
                        value={props.values.phone}
                        onChange={(e) =>
                          props.setFieldValue("phone", e.target.value)
                        }
                        mask="999-999-9999"
                        // maskChar='_'
                      />
                      <div className="validation-error">
                        <ErrorMessage name="phone" />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col sm={3}>
                    <Form.Group controlId="phone_ext">
                      <Form.Label>Ext</Form.Label>
                      <Field name="phone_ext" className="form-control" />
                      <div className="validation-error">
                        <ErrorMessage name="phone_ext" />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Field name="email" className="form-control" />
                  <div className="validation-error">
                    <ErrorMessage name="email" />
                  </div>
                </Form.Group>
                <div className="bg-secondary text-white text-center p-1">
                  <h3>General Agent Information</h3>
                </div>
                <p className="font-weight-light bg-light p-2">
                  If you broker your business through a GA, enter that name and
                  code. If you work for the GA, please enter your agency
                  information.
                </p>
                <Form.Group controlId="general_agent_name">
                  <Form.Label>General Agent Name</Form.Label>
                  <Field name="general_agent_name" className="form-control" />
                  <div className="validation-error">
                    <ErrorMessage name="general_agent_name" />
                  </div>
                </Form.Group>
                <Form.Group controlId="general_agent_code">
                  <Form.Label>General Agent Code</Form.Label>
                  <Field name="general_agent_code" className="form-control" />
                  <div className="validation-error">
                    <ErrorMessage name="general_agent_code" />
                  </div>
                </Form.Group>
                <div className="bg-secondary text-white text-center p-1">
                  <h3>Policy Download Contact</h3>
                </div>
                <p className="font-weight-light bg-light p-2">
                  Who in your agency manages the policy download process?
                </p>
                <Form.Group controlId="download_contact_name">
                  <Form.Label>Contact Full Name</Form.Label>
                  <Field
                    name="download_contact_name"
                    className="form-control"
                  />
                  <div className="validation-error">
                    <ErrorMessage name="download_contact_name" />
                  </div>
                </Form.Group>
                <Row>
                  <Col sm={9}>
                    <Form.Group controlId="download_contact_phone">
                      <Form.Label>Contact Phone</Form.Label>
                      <InputMask
                        name="download_contact_phone"
                        type="text"
                        className="form-control"
                        value={props.values.download_contact_phone}
                        onChange={(e) =>
                          props.setFieldValue(
                            "download_contact_phone",
                            e.target.value
                          )
                        }
                        mask="999-999-9999"
                        maskChar="_"
                      />
                      <div className="validation-error">
                        <ErrorMessage name="download_contact_phone" />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col sm={3}>
                    <Form.Group controlId="download_contact_phone_ext">
                      <Form.Label>Ext</Form.Label>
                      <Field
                        name="download_contact_phone_ext"
                        className="form-control"
                      />
                      <div className="validation-error">
                        <ErrorMessage name="download_contact_phone_ext" />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group controlId="download_contact_email">
                  <Form.Label>Contact Email</Form.Label>
                  <Field
                    name="download_contact_email"
                    className="form-control"
                  />
                  <div className="validation-error">
                    <ErrorMessage name="download_contact_email" />
                  </div>
                </Form.Group>
                <div className="bg-secondary text-white text-center p-1">
                  <h3>Agency Management System</h3>
                </div>
                <p className="font-weight-light bg-light p-2">
                  What software package do you use?
                </p>
                <Form.Group controlId="software">
                  <Form.Label>Software Vendor</Form.Label>
                  <Field name="software" className="form-control" />
                  <div className="validation-error">
                    <ErrorMessage name="software" />
                  </div>
                </Form.Group>
                <Form.Group controlId="software_version">
                  <Form.Label>Version</Form.Label>
                  <Field name="software_version" className="form-control" />
                  <div className="validation-error">
                    <ErrorMessage name="software_version" />
                  </div>
                </Form.Group>
                <div className="bg-secondary text-white text-center p-1">
                  <h3>Agency IVANS Information</h3>
                </div>
                <p className="font-weight-light bg-light p-2">
                  Policy information is sent to your agency system via IVANS.
                  Please enter the following information about your IVANS
                  account.
                </p>
                <Form.Group controlId="have_IVANS">
                  <Form.Label>
                    Do you have an active IVANS account for policy downloads?
                  </Form.Label>
                  <Field as="select" name="have_IVANS" className="form-control">
                    <option value="none">Choose...</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </Field>
                  <div className="validation-error">
                    <ErrorMessage name="have_IVANS" />
                  </div>
                </Form.Group>
                {props.values.have_IVANS === "yes" && (
                  <>
                    <Form.Group controlId="y_account">
                      <Form.Label>Y Account Number</Form.Label>
                      <Field name="y_account" className="form-control" />
                      <div className="validation-error">
                        <ErrorMessage name="y_account" />
                      </div>
                    </Form.Group>
                    <Form.Group controlId="batch_id">
                      <Form.Label>Batch ID</Form.Label>
                      <Field name="batch_id" className="form-control" />
                      <div className="validation-error">
                        <ErrorMessage name="batch_id" />
                      </div>
                    </Form.Group>
                    <Form.Group controlId="machine_address">
                      <Form.Label>Machine Address</Form.Label>
                      <Field name="machine_address" className="form-control" />
                      <div className="validation-error">
                        <ErrorMessage name="machine_address" />
                      </div>
                    </Form.Group>
                  </>
                )}
                <div className="bg-secondary text-white text-center p-1">
                  <h3>Agency Commission Information</h3>
                </div>
                <p className="font-weight-light bg-light p-2">
                  We can add commission information for those products that are
                  part of the policy download. You must enter all of the codes
                  used by your agency. If you receive your commission from a GA,
                  no data will be transmitted.
                </p>
                <FieldArray
                  id="commission_agency_codes"
                  name="commission_agency_codes"
                  render={(arrayHelpers) => {
                    return (
                      <div>
                        {props.values.commission_agency_codes.map(
                          (cac, index) => (
                            <div key={index}>
                              <div>
                                <Form.Group
                                  controlId={`commission_agency_codes.${index}.agency_code`}
                                >
                                  {index === 0 && (
                                    <Form.Label>Agency Code</Form.Label>
                                  )}
                                  <div className="d-flex">
                                    <div className="flex-grow-1">
                                      <Field
                                        name={`commission_agency_codes.${index}.agency_code`}
                                        className="form-control"
                                      />
                                    </div>
                                    <div className="align-self-center">
                                      <Button
                                        className="ml-3 close"
                                        onClick={() =>
                                          arrayHelpers.remove(index)
                                        }
                                      >
                                        <span style={{ color: "red" }}>
                                          &times;
                                        </span>
                                      </Button>
                                    </div>
                                  </div>

                                  <div className="validation-error">
                                    <ErrorMessage
                                      name={`commission_agency_codes.${index}.agency_code`}
                                    />
                                  </div>
                                </Form.Group>
                              </div>
                            </div>
                          )
                        )}
                        <div>
                          <Button
                            variant="link"
                            onClick={() =>
                              arrayHelpers.push({ agency_code: "" })
                            }
                          >
                            + Add Additional Code
                          </Button>
                        </div>
                      </div>
                    );
                  }}
                />
                <Form.Group controlId="authorized" className="mb-0 pt-3">
                  <Row>
                    <Col xs={1} style={{ textAlign: "right" }}>
                      <Field
                        id={"authorized"}
                        type={"checkbox"}
                        name="authorized"
                        style={{ width: "50%", height: "50%" }}
                      />
                    </Col>
                    <Col>
                      <p className="font-weight-light pt-2 mb-0">
                        By checking this box, I represent that I am authorized
                        to act on behalf of the agency identified above and
                        consent to the electronic delivery of the following
                        agency downloads, as available and applicable to my
                        agency: (Please check all that apply)
                      </p>
                    </Col>
                  </Row>
                  <div className="validation-error">
                    <ErrorMessage name="authorized" />
                  </div>
                </Form.Group>
                <Form.Group controlId="checked_policy_dl" className="mb-0 mt-2">
                  <Row>
                    <Col xs={2} />
                    <Col xs={1} style={{ textAlign: "right" }} className="pt-2">
                      <Field
                        id={"checked_policy_dl"}
                        type={"checkbox"}
                        name="checked.policy_dl"
                        style={{ width: "50%", height: "50%" }}
                      />
                    </Col>
                    <Col>
                      <p className="font-weight-light pt-2">Policy Downloads</p>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group controlId="checked_comm_reporting" className="mb-0">
                  <Row>
                    <Col xs={2} />
                    <Col xs={1} style={{ textAlign: "right" }} className="pt-2">
                      <Field
                        id={"checked_comm_reporting"}
                        type={"checkbox"}
                        name={"checked.comm_reporting"}
                        style={{ width: "50%", height: "50%" }}
                      />
                    </Col>
                    <Col>
                      <p className="font-weight-light pt-2">
                        Commission Reporting
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={2} />
                    <Col xs={1} />
                    <Col>
                      <div className="validation-error">
                        <ErrorMessage name="checked" />
                      </div>
                    </Col>
                  </Row>
                </Form.Group>
                <div className="d-flex justify-content-end py-3">
                  <Button variant="primary" onClick={() => props.submitForm()}>
                    Submit
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </>
      )}
    </Formik>
  );
};

AgencyAcordRequestForm.propTypes = {
  sendRequest: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
};

export default connect(null, { sendRequest, setAlert })(AgencyAcordRequestForm);
