import { Field, ErrorMessage } from 'formik';
import InputMask from 'react-input-mask';
import React from 'react';
import Form from 'react-bootstrap/Form';

const PolicyVerificationFormComponent = ({ props }) => {

  return (
    <>
      <div className='bg-secondary text-white text-center p-1 mb-1'>
        <h3>Policyholder Information</h3>
      </div>
      <Form.Group controlId='personal_info.full_names'>
        <Form.Label>First and Last Name(s)</Form.Label>
        <Field name='personal_info.full_names' className='form-control' />
        <Form.Text className='text-muted'>
          <i>...as it appears on policy</i>
        </Form.Text>
        <div className='validation-error'>
          <ErrorMessage name='personal_info.full_names' />
        </div>
      </Form.Group>
      <Form.Group controlId='personal_info.policy_number'>
        <Form.Label>Policy Number</Form.Label>
        <Field name='personal_info.policy_number' className='form-control' />
        <div className='validation-error'>
          <ErrorMessage name='personal_info.policy_number' />
        </div>
      </Form.Group>
      <Form.Group controlId='personal_info.DOB'>
        <Form.Label>Date of Birth</Form.Label>
        <InputMask
          name='personal_info.DOB'
          type='text'
          className='form-control'
          value={props.values.DOB}
          onChange={e => props.setFieldValue('personal_info.DOB', e.target.value)}
          onBlur={props.onBlur}
          onError={props.onError}
          placeholder='MM-DD-YYYY'
          mask='99-99-9999'
          maskChar='_'
        />
        <div className='validation-error'>
          <ErrorMessage name='personal_info.DOB' />
        </div>
      </Form.Group>
      <Form.Group controlId='personal_info.phone'>
        <Form.Label>Phone</Form.Label>
        <InputMask
          name='personal_info.phone'
          className='form-control'
          type='text'
          value={props.values.personal_info.phone}
          onChange={e => props.setFieldValue('personal_info.phone', e.target.value)}
          onBlur={props.onBlur}
          onError={props.onError}
          mask='(999) 999-9999'
          maskChar='_'
        />
        <div className='validation-error'>
          <ErrorMessage name='personal_info.phone' />
        </div>
      </Form.Group>
      <Form.Group controlId='personal_info.mobile_phone'>
        <Form.Label>Mobile Phone</Form.Label>
        <InputMask
          name='personal_info.mobile_phone'
          className='form-control'
          type='text'
          value={props.values.personal_info.mobile_phone}
          onChange={e => props.setFieldValue('personal_info.mobile_phone', e.target.value)}
          onBlur={props.onBlur}
          onError={props.onError}
          mask='(999) 999-9999'
          maskChar='_'
        />
        <div className='validation-error'>
          <ErrorMessage name='personal_info.mobile_phone' />
        </div>
      </Form.Group>
      <Form.Group controlId='personal_info.email'>
        <Form.Label>Email</Form.Label>
        <Field name='personal_info.email' className='form-control' />
        <div className='validation-error'>
          <ErrorMessage name='personal_info.email' />
        </div>
      </Form.Group>
      <Form.Group controlId='personal_info.contact_method'>
        <Form.Label>Best method of contact</Form.Label>
        <Field
          as='select'
          name='personal_info.contact_method'
          className='form-control'>
          <option value='none'>Choose...</option>
          <option value='phone'>Phone</option>
          <option value='mobile_phone'>Mobile</option>
          <option value='email'>Email</option>
        </Field>
        <div className='validation-error'>
          <ErrorMessage name='personal_info.contact_method' />
        </div>
      </Form.Group>
      <Form.Group controlId='personal_info.contact_time'>
        <Form.Label>Best time to contact</Form.Label>
        <Field
          as='select'
          name='personal_info.contact_time'
          className='form-control'>
          <option value='No_Time_Selected'>Choose...</option>
          <option value='Before8am'>Before 8:00 AM</option>
          <option value='8AM_9AM'>8:00 AM to 9:00 AM</option>
          <option value='9AM_10AM'>9:00 AM to 10:00 AM</option>
          <option value='10AM_11AM'>10:00 AM to 11:00 AM</option>
          <option value='11AM_Noon'>11:00 AM to Noon</option>
          <option value='Noon_1PM'>Noon to 1:00 PM</option>
          <option value='1PM_2PM'>1:00 PM to 2:00 PM</option>
          <option value='2PM_3PM'>2:00 PM to 3:00 PM</option>
          <option value='3PM_4PM'>3:00 PM to 4:00 PM</option>
          <option value='4PM_5PM'>4:00 PM to 5:00 PM</option>
          <option value='5PM_6PM'>5:00 PM to 6:00 PM</option>
          <option value='After6PM'>After 6:00 PM</option>
        </Field>
        <div className='validation-error'>
          <ErrorMessage name='personal_info.contact_time' />
        </div>
      </Form.Group>
    </>
  );
};

export default PolicyVerificationFormComponent;
