import * as yup from "yup";
import { states } from "../actions/types";

const contact_times = [
  "Before8am",
  "8AM_9AM",
  "9AM_10AM",
  "10AM_11AM",
  "11AM_Noon",
  "Noon_1PM",
  "1PM_2PM",
  "2PM_3PM",
  "3PM_4PM",
  "4PM_5PM",
  "5PM_6PM",
  "After6PM",
];

const contact_method = ["phone", "mobile_phone", "email"];

export const agentUserLinkingSchema = yup.object({
  requestor_name: yup.string().required("Please provide a Requestor Name"),
  requestor_email: yup
    .string()
    .required("Please provide an email address")
    .email("Please provide a valid email address"),
  requestor_phone: yup
    .string()
    .required("Please provide your phone number")
    .matches(
      /^[(]?\d{3}[)]?[(\s)?.-]\d{3}[\s.-]\d{4}$/,
      "Please enter a valid phone number"
    ),
  requestor_phone_ext: yup.string(),
  agency_name: yup.string().required("Please provide an agency name"),
  agency_code: yup.string().required("Please provide an agency code"),
  sub_producer_name: yup.string(),
  sub_producer_code: yup.string(),
  linked_users: yup.array().of(
    yup.object().shape({
      user_id: yup.string(),
      linked_agency_code: yup.string(),
      linked_sub_producer_code: yup.string(),
      company_numbers: yup.array().of(
        yup.object().shape({
          linked_phone: yup
            .string()
            .matches(
              /^[(]?\d{3}[)]?[(\s)?.-]\d{3}[\s.-]\d{4}$/,
              "Please enter a valid phone number"
            ),
          linked_phone_ext: yup.string(),
        })
      ),
    })
  ),
});

export const policyNameChangeSchema = yup.object({
  personal_info: yup.object().shape({
    full_names: yup.string().required("Please provide your full name"),
    policy_number: yup
      .string()
      .required("Policy Number is required")
      .min(9, "Policy number must be at least 9 characters")
      .max(13, "Policy number must not be more than 13 characters long"),
    DOB: yup
      .string()
      .required("Please provide a date of birth")
      .notOneOf(
        ["__-__-____", "MM-DD-YYYY"],
        "Please enter a valid date of birth"
      ),
    email: yup
      .string()
      .email("Please provide a valid email address")
      .required("Please provide an email address"),
    phone: yup.string().when("contact_method", {
      is: (contact_method) => contact_method === "phone",
      then: yup
        .string()
        .required("Please provide a phone number")
        .matches(
          /^[(]?\d{3}[)]?[(\s)?.-]\d{3}[\s.-]\d{4}$/,
          "Please enter a valid phone number"
        ),
    }),
    mobile_phone: yup.string().when("contact_method", {
      is: (contact_method) => contact_method === "mobile_phone",
      then: yup
        .string()
        .required("Please provide a mobile phone number")
        .matches(
          /^[(]?\d{3}[)]?[(\s)?.-]\d{3}[\s.-]\d{4}$/,
          "Please enter a valid mobile phone number"
        ),
    }),
    contact_method: yup.string().oneOf(contact_method),
    contact_time: yup.string().oneOf(contact_times, "Please select a value"),
  }),
  name_change_info: yup.object().shape({
    new_full_names: yup.string().required("Please provide your full name"),
    reason_for_change: yup
      .string()
      .required("Please select a reason for change")
      .oneOf(["Marriage", "Divorce", "Widow", "Sold Property", "Other"]),
    comments: yup.string().when("reason_for_change", {
      is: (reason_for_change) => reason_for_change === "Other",
      then: yup
        .string()
        .required("Please describe your reason for name change"),
    }),
  }),
});

export const policyAddressChangeSchema = yup.object({
  personal_info: yup.object().shape({
    full_names: yup.string().required("Please provide your full name"),
    policy_number: yup
      .string()
      .required("Please provide your policy number")
      .min(9)
      .max(13),
    DOB: yup
      .string()
      .required("Please provide a date of birth")
      .notOneOf(
        ["__-__-____", "MM-DD-YYYY"],
        "Please enter a valid date of birth"
      ),
    email: yup
      .string()
      .email("Please provide a valid email address")
      .required("Please provide an email address"),
    phone: yup.string().when("contact_method", {
      is: (contact_method) => contact_method === "phone",
      then: yup
        .string()
        .required("Please provide a phone number")
        .matches(
          /^[(]?\d{3}[)]?[(\s)?.-]\d{3}[\s.-]\d{4}$/,
          "Please enter a valid phone number"
        ),
    }),
    mobile_phone: yup.string().when("contact_method", {
      is: (contact_method) => contact_method === "mobile_phone",
      then: yup
        .string()
        .required("Please provide a mobile phone number")
        .matches(
          /^[(]?\d{3}[)]?[(\s)?.-]\d{3}[\s.-]\d{4}$/,
          "Please enter a valid mobile phone number"
        ),
    }),
    contact_method: yup.string().oneOf(contact_method),
    contact_time: yup
      .string()
      .oneOf(contact_times, "Please select a best method of contact"),
  }),
  new_address_info: yup.object().shape({
    new_address_street: yup.string().required("Please enter a street address"),
    new_address_street_2: yup.string(),
    new_address_city: yup.string().required("Please provide a city"),
    new_address_state: yup
      .string()
      .required("Please select a state")
      .oneOf(states.map((state) => state.abbreviation)),
    new_address_zipcode: yup
      .string()
      .required("Please provide a zip code")
      .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, "Please enter a valid zip code"),
  }),
});

export const policyMailingAddressChangeSchema = yup.object({
  personal_info: yup.object().shape({
    full_names: yup.string().required("Please provide your full name"),
    policy_number: yup.string().required("Please provide your policy number"),
    DOB: yup
      .string()
      .required("Please provide a date of birth")
      .notOneOf(
        ["__-__-____", "MM-DD-YYYY"],
        "Please enter a valid date of birth"
      ),
    email: yup
      .string()
      .email("Please provide a valid email address")
      .required("Please provide an email address"),
    phone: yup.string().when("contact_method", {
      is: (contact_method) => contact_method === "phone",
      then: yup
        .string()
        .required("Please provide a phone number")
        .matches(
          /^[(]?\d{3}[)]?[(\s)?.-]\d{3}[\s.-]\d{4}$/,
          "Please enter a valid phone number"
        ),
    }),
    mobile_phone: yup.string().when("contact_method", {
      is: (contact_method) => contact_method === "mobile_phone",
      then: yup
        .string()
        .required("Please provide a mobile phone number")
        .matches(
          /^[(]?\d{3}[)]?[(\s)?.-]\d{3}[\s.-]\d{4}$/,
          "Please enter a valid mobile phone number"
        ),
    }),
    contact_method: yup.string().oneOf(contact_method),
    contact_time: yup
      .string()
      .oneOf(contact_times, "Please select a best method of contact"),
  }),
  new_mailing_address_info: yup.object().shape({
    new_mailing_address_street: yup
      .string()
      .required("Please provide a street address"),
    new_mailing_address_street_2: yup.string(),
    new_mailing_address_po_box: yup.string(),
    new_mailing_address_city: yup.string().required("Please provide a city"),
    new_mailing_address_state: yup
      .string()
      .required("Please select a state")
      .oneOf(states.map((state) => state.abbreviation)),
    new_mailing_address_zipcode: yup
      .string()
      .required("Please provide a zip code")
      .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, "Please enter a valid zip code"),
  }),
});

export const policyDocumentRequestSchema = yup.object({
  personal_info: yup.object().shape({
    full_names: yup.string().required("Please provide your full name"),
    policy_number: yup
      .string()
      .required("Please provide your policy number")
      .min(9, "Policy number must be at least 9 characters")
      .max(13, "Policy number must not be more than 13 characters long"),
    DOB: yup
      .string()
      .required("Please provide a date of birth")
      .notOneOf(
        ["__-__-____", "MM-DD-YYYY"],
        "Please enter a valid date of birth"
      ),
    email: yup
      .string()
      .email("Please provide a valid email address")
      .required("Please provide an email address"),
    phone: yup.string().when("contact_method", {
      is: (contact_method) => contact_method === "phone",
      then: yup
        .string()
        .required("Please provide a phone number")
        .matches(
          /^[(]?\d{3}[)]?[(\s)?.-]\d{3}[\s.-]\d{4}$/,
          "Please enter a valid phone number"
        ),
    }),
    mobile_phone: yup.string().when("contact_method", {
      is: (contact_method) => contact_method === "mobile_phone",
      then: yup
        .string()
        .required("Please provide a mobile phone number")
        .matches(
          /^[(]?\d{3}[)]?[(\s)?.-]\d{3}[\s.-]\d{4}$/,
          "Please enter a valid mobile phone number"
        ),
    }),
    contact_method: yup.string().oneOf(contact_method),
    contact_time: yup
      .string()
      .oneOf(contact_times, "Please select a best method of contact"),
  }),
  document_request: yup.object().shape({
    request_type: yup
      .string()
      .required("Please select a request type")
      .oneOf([
        "none",
        "Evidence of Insurance",
        "Declaration Page",
        "Complete Policy",
      ]),
    contact_method: yup
      .string()
      .required("Please select a contact method")
      .oneOf(["none", "Email", "Fax", "Mail"]),
    contact_name: yup.string().required("Please provide a contact name"),
    fax_number: yup.string().when("contact_method", {
      is: (contact_method) => contact_method === "Fax",
      then: yup
        .string()
        .required("Please provide a fax number")
        .matches(
          /^[(]?\d{3}[)]?[(\s)?.-]\d{3}[\s.-]\d{4}$/,
          "Please enter a valid phone number"
        ),
    }),
    contact_email: yup.string().when("contact_method", {
      is: (contact_method) => contact_method === "Email",
      then: yup
        .string()
        .email("Please provide a valid email address")
        .required("Please provide an email address"),
    }),
    company: yup.string().when("contact_method", {
      is: (contact_method) => contact_method === "Mail",
      then: yup.string().required("Please a company name"),
    }),
    mailing_address_street: yup.string().when("contact_method", {
      is: (contact_method) => contact_method === "Mail",
      then: yup.string().required("Please provide a street address"),
    }),
    mailing_address_street_2: yup.string(),
    mailing_address_city: yup.string().when("contact_method", {
      is: (contact_method) => contact_method === "Mail",
      then: yup.string().required("Please provide a city"),
    }),
    mailing_address_state: yup.string().when("contact_method", {
      is: (contact_method) => contact_method === "Mail",
      then: yup
        .string()
        .required("Please select a state")
        .oneOf(states.map((state) => state.abbreviation)),
    }),
    mailing_address_zipcode: yup.string().when("contact_method", {
      is: (contact_method) => contact_method === "Mail",
      then: yup
        .string()
        .required("Please select a zip code")
        .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, "Please enter a valid zip code"),
    }),
  }),
});

export const agencyAcordRequestSchema = yup.object({
  amig_agency_code: yup.string().required("Please provide an agency code"),
  agency_name: yup.string().required("Please provide an agency name"),
  street: yup.string().required("Please provide a street address"),
  street_2: yup.string(),
  city: yup.string().required("Please provide a city"),
  state: yup
    .string()
    .required("Please select a state")
    .oneOf(states.map((state) => state.abbreviation)),
  zipcode: yup
    .string()
    .required("Please provide a zip code")
    .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, "Please enter a valid zip code"),
  phone: yup
    .string()
    .required("Please provide a phone number")
    .matches(
      /^[(]?\d{3}[)]?[(\s)?.-]\d{3}[\s.-]\d{4}$/,
      "Please enter a valid phone number"
    ),
  email: yup
    .string()
    .email("Please provide a valid email address")
    .required("Please provide an email address"),
  general_agent_name: yup.string().required("Please provide a Agent Name"),
  general_agent_code: yup.string().required("Please provide an Agent Code"),
  download_contact_name: yup.string().required("Please provide a contact name"),
  download_contact_phone: yup
    .string()
    .required("Please provide an Agent contact phone")
    .matches(/^[2-9]\d{2}-\d{3}-\d{4}$/, "Please enter a valid phone number"),
  download_contact_email: yup
    .string()
    .email("Please provide a valid email address")
    .required("Please provide an Agent email address"),
  software: yup.string().required("Please provide your vendor software"),
  software_version: yup.string(),
  have_IVANS: yup
    .string()
    .required("Please select a value")
    .oneOf(["yes", "no"]),
  y_account: yup.string(),
  batch_id: yup.string(),
  machine_address: yup.string(),
  commission_agency_codes: yup.array().of(
    yup.object().shape({
      agency_code: yup.string(),
    })
  ),
  authorized: yup
    .bool()
    .required("Agency consent is required")
    .oneOf([true], "Agency consent is required"),
  checked: yup
    .object(
      {
        policy_dl: yup.boolean().nullable(),
        // .required(),
        // .when("comm_reporting", {
        //   is: false,
        //   then: yup
        //     .boolean()
        //     .required("One or both of these authorities is required"),
        // }),
        comm_reporting: yup.boolean().nullable(),
        // .when("policy_dl", {
        //   is: null || false,
        //   then: yup
        //     .boolean()
        //     .nullable()
        //     .required("One or both of these authorities is required"),
        //   otherwise: yup.boolean(),
        // }),
      }
      // [
      //   [true, true],
      //   [true, false],
      //   [false, true],
      // ]
    )
    .test("checkboxTest", null, (obj) => {
      if (obj.policy_dl === true || obj.comm_reporting === true) {
        return true; // everything is fine
      }

      return new yup.ValidationError(
        "Please select at least one authorization",
        null,
        "checked"
      );
    }),
});
