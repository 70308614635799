import React from "react";
import Container from "react-bootstrap/Container";

// Redux
import { Provider } from "react-redux";
import store from "./store";

// React Router
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// Components
import Navigation from "./components/layout/Navigation";
import Footer from "./components/layout/Footer";
import Alert from "./components/layout/Alerts";
import PolicyNameChangeForm from "./components/forms/policyNameChangeForm";
import PolicyMailingAddressChangeForm from "./components/forms/policyMailingAddressChangeForm";
import PolicyDocumentRequestForm from "./components/forms/policyDocumentRequestForm";
import AgencyAcordRequestForm from "./components/forms/agencyAcordRequestForm";
import AgentUserLinkingRequestForm from "./components/forms/agentUserLinkingRequestForm";

export default () => {

  return (
    <Provider store={store}>
      <Router>
        <>
          <Navigation />
          <Container>
            <Alert />
            <Switch>
              <Route exact path='/policy-name-change' component={PolicyNameChangeForm} />
              <Route exact path='/policy-mailing-address-change' component={PolicyMailingAddressChangeForm} />
              <Route exact path='/policy-document-request' component={PolicyDocumentRequestForm} />
              <Route exact path='/agency-acord-request' component={AgencyAcordRequestForm} />
              <Route exact path='/agent-user-linking-request' component={AgentUserLinkingRequestForm} />
            </Switch>
          </Container>
          <Footer />
        </>
      </Router>
    </Provider>
  );
};
