import { combineReducers } from "redux";
import alert from "./alert";
import policyChangeForm from "./policyChangeForm";
import policyDocumentRequestForm from "./policyDocumentRequestForm";

export default combineReducers({
  alert,
  policyChangeForm,
  policyDocumentRequestForm,
});
