import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import PolicyVerificationFormComponent from './policyVerificationFormComponent';
import sendRequest from '../../actions/sendRequest';
import { setAlert } from '../../actions/alert';
import { FormTypes } from '../../actions/types';
import { Formik, Field, ErrorMessage } from 'formik';
import queryString from 'query-string';
import { policyNameChangeSchema } from '../../validation/schemas';
import { useLocation } from 'react-router-dom';

const PolicyNameChangeForm = ({ sendRequest, setAlert }) => {
  const initialValues = {
    personal_info: {
      full_names: '',
      policy_number: '',
      DOB: '',
      email: '',
      phone: '',
      mobile_phone: '',
      contact_method: '',
      contact_time: ''
    },
    name_change_info: {
      new_full_names: '',
      reason_for_change: '',
      comments: ''
    }
  };

  const qs = queryString.parse(useLocation().search);

    const handleSubmit = async values => {
    window.scrollTo(0, 0);
    const { personal_info, name_change_info } = values;
    
    try {
      
      const response = await sendRequest(
        { personal_info, name_change_info },
        FormTypes.POLICY_NAME_CHANGE_REQUEST,
        qs.referrer
      );
      response.status >= 200 &&
        response.status < 300 &&
        setAlert(
          'Your policy name change request has been submitted. Please check your email for confirmation.',
          'success',
          20000
        );
    } catch (error) {
      setAlert(
        'An error occured and we were unable to receive your policy name change request.',
        'danger',
        20000
      );
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={policyNameChangeSchema}
      onSubmit={values => handleSubmit(values)}>
      {props => (
        <Row>
          <Col sm={12} md={{ span: 8, offset: 2 }}>
            <div>
              <p className='display-4 text-center text-primary p-1'>
                Policyholder Name Change
              </p>
            </div>
            <div className='small bg-light p-2'>
              <p>
                Instructions for submitting this form to the American Modern
                Insurance Group:
              </p>
              <p>
                Clicking on the Submit button on the last page of this form will
                electronically send your information to the American Modern
                Insurance Group.
              </p>
              <p>
                This form allows you to make a change to your name noted on your
                policy. If you need help with this form please contact Customer
                Care at 1-800-543-2644.
              </p>
            </div>
            <Form
              onSubmit={props.handleSubmit}
              className='small font-weight-bold'>
              <PolicyVerificationFormComponent props={props} />
              <div className='bg-secondary text-white text-center p-1 mb-1'>
                <h3>Name Change Information</h3>
              </div>
              <Form.Group controlId='name_change_info.new_full_names'>
                <Form.Label>New Full Names</Form.Label>
                <Field
                  name='name_change_info.new_full_names'
                  className='form-control'
                />
                <Form.Text className='text-muted'>
                  <i>...as you'd like it to appear on your policy</i>
                </Form.Text>
                <div className='validation-error'>
                  <ErrorMessage name='name_change_info.new_full_names' />
                </div>
              </Form.Group>
              <Form.Group controlId='name_change_info.reason_for_change'>
                <Form.Label>Reason for Change</Form.Label>
                <Field
                  as='select'
                  name='name_change_info.reason_for_change'
                  className='form-control'>
                  <option value='none'>Choose...</option>
                  <option value='Marriage'>Marriage</option>
                  <option value='Divorce'>Divorce</option>
                  <option value='Widow'>Widowed/Widower</option>
                  <option value='Sold Property'>Sold Property</option>
                  <option value='Other'>
                    Other (describe in Comments below)
                  </option>
                </Field>
                <div className='validation-error'>
                  <ErrorMessage name='name_change_info.reason_for_change' />
                </div>
              </Form.Group>
              <Form.Group controlId='name_change_info.comments'>
                <Form.Label>Comments</Form.Label>
                <Field
                  as='textarea'
                  name='name_change_info.comments'
                  className='form-control'
                />
                <div className='validation-error'>
                  <ErrorMessage name='name_change_info.comments' />
                </div>
              </Form.Group>
              <div className='d-flex justify-content-end py-3'>
                <Button variant='primary' onClick={() => props.submitForm()}>
                  Submit
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      )}
    </Formik>
  );
};

PolicyNameChangeForm.propTypes = {
  sendRequest: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired
};

export default connect(null, { sendRequest, setAlert })(PolicyNameChangeForm);
