import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import PolicyVerificationFormComponent from './policyVerificationFormComponent';
import PolicyDocumentRequestFormComponent from './policyDocumentRequestFormComponent';
import sendRequest from '../../actions/sendRequest';
import { setAlert } from '../../actions/alert';
import { FormTypes } from '../../actions/types';
import { Formik } from 'formik';
import { policyDocumentRequestSchema } from '../../validation/schemas';

const PolicyDocumentRequestForm = ({ sendRequest, setAlert }) => {
  const initialValues = {
    personal_info: {
      full_names: '',
      policy_number: '',
      DOB: '',
      email: '',
      phone: '',
      mobile_phone: '',
      contact_method: '',
      contact_time: ''
    },
    document_request: {
      request_type: '',
      fax_number: '',
      contact_email: '',
      company: '',
      contact_method: '',
      contact_name: '',
      mailing_address_street: '',
      mailing_address_street_2: '',
      mailing_address_city: '',
      mailing_address_state: '',
      mailing_address_zipcode: ''
    }
  };

  const handleSubmit = async values => {
    window.scrollTo(0, 0);
    const { personal_info, document_request } = values;

    try {
      const response = await sendRequest(
        { personal_info, document_request },
        FormTypes.POLICY_DOCUMENT_REQUEST
      );
      response.status >= 200 &&
        response.status < 300 &&
        setAlert(
          'Your policy address change request has been submitted. Please check your email for confirmation.',
          'success',
          20000
        );
    } catch (error) {
      setAlert(
        'An error occured and we were unable to receive your policy address change request.',
        'danger',
        20000
      );
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={policyDocumentRequestSchema}
      onSubmit={values => handleSubmit(values)}>
      {props => (
        <Row>
          <Col sm={12} md={{ span: 8, offset: 2 }}>
            <div>
              <p className='display-4 text-center text-primary'>
                Policy Documents Request
              </p>
            </div>
            <div className='small bg-light p-2'>
              <p>
                Instructions for submitting this form to the American Modern
                Insurance Group:
              </p>
              <p>
                Clicking on the Submit button on the last page of this form will
                electronically send your information to the American Modern
                Insurance Group. However, you also have the option of printing
                the form and faxing or mailing the page(s) to us.
              </p>
              <p>
                This form allows you to make a request for policy documents. If
                you need help with this form please contact Customer Care at
                1-800-543-2644.
              </p>
            </div>
            <Form
              onSubmit={props.handleSubmit}
              className='small font-weight-bold'>
              <PolicyVerificationFormComponent props={props} />
              <div className='bg-secondary text-white text-center p-1 mb-1'>
                <h3>Policy Request Information</h3>
              </div>
              <PolicyDocumentRequestFormComponent props={props} />
              <div className='d-flex justify-content-end py-3'>
                <Button variant='primary' type='submit'>
                  Submit
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      )}
    </Formik>
  );
};

PolicyDocumentRequestForm.propTypes = {
  sendRequest: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired
};

export default connect(null, { sendRequest, setAlert })(
  PolicyDocumentRequestForm
);
