import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import store from '../../store';

import Alert from 'react-bootstrap/Alert';
import { REMOVE_ALERT } from '../../actions/types';

const Alerts = ({ alerts }) => {

  return alerts !== null &&
  alerts.length > 0 &&
  alerts.map(alert => (
    <Alert className='mt-3 text-center' key={alert.id} variant={alert.alertType} onClose={() => store.dispatch({type: REMOVE_ALERT, payload: alert.id})} dismissible>
      {alert.msg}
    </Alert>
  ));
}

Alert.propTypes = {
  alerts: PropTypes.array
};

const mapStateToProps = state => ({
  alerts: state.alert
});

export default connect(mapStateToProps)(Alerts);