import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputMask from 'react-input-mask';
import LinkedUserFormComponent from './linkedUserFormComponent';
import sendRequest from '../../actions/sendRequest';
import { setAlert } from '../../actions/alert';
import { FormTypes } from '../../actions/types';
import { Formik, Field, FieldArray, ErrorMessage } from 'formik';
import { agentUserLinkingSchema } from '../../validation/schemas';
import '../../index.css';

const AgentUserLinkingRequestForm = ({ sendRequest, setAlert }) => {
  const initialValues = {
    requestor_name: '',
    requestor_email: '',
    requestor_phone: '',
    requestor_phone_ext: '',
    agency_name: '',
    agency_code: '',
    sub_producer_name: '',
    sub_producer_code: '',
    linked_users: [
      {
        user_id: '',
        linked_agency_code: '',
        linked_sub_producer_code: '',
        company_numbers: [{ linked_no: ''}]
      }
    ],
    comments: ''
  };

  const handleSubmit = async values => {
    window.scrollTo(0, 0);

    if (values.requestor_phone_ext) {
      values.requestor_phone = `${values.requestor_phone} ext: ${values.requestor_phone_ext}`;
      delete values.requestor_phone_ext;
    }

    const linked_users = values.linked_users.map(lu => {
      const company_numbers = lu.company_numbers.map(cn => {
        // if (cn.linked_phone_ext) {
        //   cn.linked_phone = `${cn.linked_phone} ext: ${cn.linked_phone_ext}`;
        // }
        // delete cn.linked_phone_ext;
        return cn;
      });
      return { ...lu, company_numbers };
    });

    try {
      const response = await sendRequest(
        { user_linking: { ...values, linked_users } },
        FormTypes.AGENT_USER_LINKING_REQUEST
      );
      response.status >= 200 &&
        response.status < 300 &&
        setAlert(
          'Your agent/user linking change request has been submitted. Please check your email for confirmation.',
          'success',
          20000
        );
    } catch (error) {
      setAlert(
        'An error occured and we were unable to receive your agent/user linking change request.',
        'danger',
        20000
      );
    }
  };

  return (
    <Row>
      <Col sm={12} md={{ span: 8, offset: 2 }}>
        <div>
          <p className='display-4 text-center text-primary'>
            Agent User Linking Request
          </p>
        </div>
        <div className='small bg-light p-2'>
          <p></p>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={agentUserLinkingSchema}
          onSubmit={values => handleSubmit(values)}>
          {props => (
            <Form
              onSubmit={props.handleSubmit}
              className='small font-weight-bold'>
              <div className='bg-secondary text-white text-center p-1 mb-1'>
                <h3>Contact Information</h3>
              </div>
              <Form.Group controlId='requestor_name'>
                <Form.Label>Requestor Name</Form.Label>
                <Field name='requestor_name' className='form-control' />
                <div className='validation-error'>
                  <ErrorMessage name='requestor_name' />
                </div>
              </Form.Group>
              <Row>
                <Col sm={10}>
                  <Form.Group>
                    <Form.Label>Requestor Phone</Form.Label>
                    <InputMask
                      name='requestor_phone'
                      value={props.values.requestor_phone}
                      onChange={e =>
                        props.setFieldValue(
                          'requestor_phone',
                          e.currentTarget.value
                        )
                      }
                      onBlur={props.onBlur}
                      onError={props.onError}
                      className='form-control'
                      mask='999-999-9999'
                      maskChar='_'
                    />
                    <div className='validation-error'>
                      <ErrorMessage name='requestor_phone' />
                    </div>
                  </Form.Group>
                </Col>
                <Col sm={2}>
                  <Form.Group controlId='requestor_phone_ext'>
                    <Form.Label>Ext</Form.Label>
                    <Field
                      name='requestor_phone_ext'
                      className='form-control'
                    />
                    <div className='validation-error'>
                      <ErrorMessage name='requestor_phone_ext' />
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              <Form.Group controlId='requestor_email'>
                <Form.Label>Email</Form.Label>
                <Field name='requestor_email' className='form-control' />
                <div className='validation-error'>
                  <ErrorMessage name='requestor_email' />
                </div>
              </Form.Group>
              <div className='bg-secondary text-white text-center p-1 mb-1'>
                <h3>Agency Information</h3>
              </div>
              <Form.Group controlId='agency_name'>
                <Form.Label>Agency Name</Form.Label>
                <Field name='agency_name' className='form-control' />
                <div className='validation-error'>
                  <ErrorMessage name='agency_name' />
                </div>
              </Form.Group>
              <Form.Group controlId='agency_code'>
                <Form.Label>Agency Code</Form.Label>
                <Field name='agency_code' className='form-control' />
                <div className='validation-error'>
                  <ErrorMessage name='agency_code' />
                </div>
              </Form.Group>
              <Form.Group controlId='sub_producer_name'>
                <Form.Label>Sub-Producer Name</Form.Label>
                <Field name='sub_producer_name' className='form-control' />
                <div className='validation-error'>
                  <ErrorMessage name='sub_producer_name' />
                </div>
              </Form.Group>
              <Form.Group controlId='sub_producer_code'>
                <Form.Label>Sub-Producer Code</Form.Label>
                <Field name='sub_producer_code' className='form-control' />
                <div className='validation-error'>
                  <ErrorMessage name='sub_producer_code' />
                </div>
              </Form.Group>
              <div className='bg-secondary text-white text-center p-1 mb-1'>
                <h3>Codes to be Linked</h3>
              </div>

              <FieldArray
                name='linked_users'
                component={LinkedUserFormComponent}
              />

              <Form.Group>
                <Form.Label>Comments</Form.Label>
                <Field name='comments' as='textarea' className='form-control' />
                <div className='validation-error'>
                  <ErrorMessage name='comments' />
                </div>
              </Form.Group>
              <div className='d-flex justify-content-end py-3'>
                <Button variant='primary' onClick={() => props.submitForm()}>
                  Submit
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Col>
    </Row>
  );
};

AgentUserLinkingRequestForm.propTypes = {
  sendRequest: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired
};

export default connect(null, { sendRequest, setAlert })(
  AgentUserLinkingRequestForm
);
