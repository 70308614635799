import axios from 'axios';
import { FORM_SUBMISSION_API_ENDPOINT } from './types';

export default (data, form_type, referrer="") => async dispatch => {

    return await axios.post(FORM_SUBMISSION_API_ENDPOINT, data, {
        params: {
          form_type,
          referrer: referrer
        }});
}