import { UPDATE_PERSONAL_INFO } from '../actions/types';

const initialState = {
  personal_info: {
    full_names: '',
    policy_number: '',
    DOB: '--',
    email:'',
    phone: '',
		mobile_phone: '',
    contact_method: '',
    contact_time: ''
  },
  name_change_info: {
    new_full_names: '',
    reason_for_change: '',
    comments: ''
  },
  new_mailing_address_info: {
    new_mailing_address_street: '',
    new_mailing_address_street_2: '',
    new_mailing_address_po_box: '',
    new_mailing_address_city: '',
    new_mailing_address_state: '',
    new_mailing_address_zipcode: ''
  },
  new_physical_address_info: {
    new_physical_address_street: '',
    new_physical_address_street_2: '',
    new_physical_address_city: '',
    new_physical_address_state: '',
    new_physical_address_zipcode: ''
  },
  isUpdate: false
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_PERSONAL_INFO:
    const { personal_info, name_change_info, new_mailing_address_info, new_physical_address_info } = payload;
    return {
        ...state,
        personal_info,
        name_change_info,
        new_mailing_address_info,
        new_physical_address_info,
        isUpdate: true
      };
    default:
      return state;
  }
};
