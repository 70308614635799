import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import PolicyVerificationFormComponent from './policyVerificationFormComponent';
import sendRequest from '../../actions/sendRequest';
import { setAlert } from '../../actions/alert';
import { FormTypes, states } from '../../actions/types';
import { Formik, Field, ErrorMessage } from 'formik';
import { policyMailingAddressChangeSchema } from '../../validation/schemas';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

const PolicyMailingAddressChangeForm = ({ sendRequest, setAlert }) => {
  const initialValues = {
    personal_info: {
      full_names: '',
      policy_number: '',
      DOB: '',
      email: '',
      phone: '',
      mobile_phone: '',
      contact_method: '',
      contact_time: ''
    },
    new_mailing_address_info: {
      new_mailing_address_street: '',
      new_mailing_address_street_2: '',
      new_mailing_address_po_box: '',
      new_mailing_address_city: '',
      new_mailing_address_state: '',
      new_mailing_address_zipcode: ''
    }
  };

  const qs = queryString.parse(useLocation().search);

  const handleSubmit = async values => {
    window.scrollTo(0, 0);
    const { personal_info, new_mailing_address_info } = values;

    try {
      const response = await sendRequest(
        { personal_info, new_mailing_address_info },
        FormTypes.POLICY_MAILING_ADDRESS_CHANGE_REQUEST,
        qs.referrer
      );
      response.status >= 200 &&
        response.status < 300 &&
        setAlert(
          'Your policy mailing address change request has been submitted. Please check your email for confirmation.',
          'success',
          20000
        );
    } catch (error) {
      setAlert(
        'An error occured and we were unable to receive your policy mailing address change request.',
        'danger',
        20000
      );
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={policyMailingAddressChangeSchema}
      onSubmit={values => handleSubmit(values)}>
      {props => (
        <Row>
          <Col sm={12} md={{ span: 8, offset: 2 }}>
            <div>
              <p className='display-4 text-center text-primary'>
                Policyholder Mailing Address Change
              </p>
            </div>
            <div className='small bg-light p-2'>
              <p>
                Instructions for submitting this form to the American Modern
                Insurance Group:
              </p>
              <p>
                Clicking on the Submit button on the last page of this form will
                electronically send your information to the American Modern
                Insurance Group.
              </p>
              <p>
                This form allows you to make a change to your mailing address
                noted on your policy. If you need help with this form please
                contact Customer Care at 1-800-543-2644.
              </p>
            </div>
            <Form
              onSubmit={props.handleSubmit}
              className='small font-weight-bold'>
              <PolicyVerificationFormComponent props={props} />
              <div className='bg-secondary text-white text-center p-1 mb-1'>
                <h3>Mailing Address Information</h3>
              </div>
              <Form.Group controlId='new_mailing_address_info.new_mailing_address_street'>
                <Form.Label>New Street Address</Form.Label>
                <Field
                  name='new_mailing_address_info.new_mailing_address_street'
                  className='form-control'
                />
                <div className='validation-error'>
                  <ErrorMessage name='new_mailing_address_info.new_mailing_address_street' />
                </div>
              </Form.Group>
              <Form.Group controlId='new_mailing_address_info.new_mailing_address_street_2'>
                <Form.Label>New Street Address 2</Form.Label>
                <Field
                  name='new_mailing_address_info.new_mailing_address_street_2'
                  className='form-control'
                />
              </Form.Group>
              <Form.Group controlId='new_mailing_address_info.new_mailing_address_po_box'>
                <Form.Label>New P.O. Box</Form.Label>
                <Field
                  name='new_mailing_address_info.new_mailing_address_po_box'
                  className='form-control'
                />
                <div className='validation-error'>
                  <ErrorMessage name='new_mailing_address_info.new_mailing_address_po_box' />
                </div>
              </Form.Group>
              <Form.Group controlId='new_mailing_address_info.new_mailing_address_city'>
                <Form.Label>New City</Form.Label>
                <Field
                  name='new_mailing_address_info.new_mailing_address_city'
                  className='form-control'
                />
                <div className='validation-error'>
                  <ErrorMessage name='new_mailing_address_info.new_mailing_address_city' />
                </div>
              </Form.Group>
              <Form.Group controlId='new_mailing_address_info.new_mailing_address_state'>
                <Form.Label>New State</Form.Label>
                <Field
                  as='select'
                  name='new_mailing_address_info.new_mailing_address_state'
                  className='form-control'>
                  <option value='none'>Choose...</option>
                  {states.map((state, index) => (
                    <option key={index}>{state.abbreviation}</option>
                  ))}
                </Field>
                <div className='validation-error'>
                  <ErrorMessage name='new_mailing_address_info.new_mailing_address_state' />
                </div>
              </Form.Group>
              <Form.Group controlId='new_mailing_address_info.new_mailing_address_zipcode'>
                <Form.Label>New Zip Code</Form.Label>
                <Field
                  name='new_mailing_address_info.new_mailing_address_zipcode'
                  className='form-control'
                />
                <div className='validation-error'>
                  <ErrorMessage name='new_mailing_address_info.new_mailing_address_zipcode' />
                </div>
              </Form.Group>
              <div className='d-flex justify-content-end py-3'>
                <Button variant='primary' onClick={() => props.submitForm()}>
                  Submit
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      )}
    </Formik>
  );
};

PolicyMailingAddressChangeForm.propTypes = {
  sendRequest: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired
};

export default connect(null, { sendRequest, setAlert })(
  PolicyMailingAddressChangeForm
);
