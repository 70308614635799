import React from 'react';
import { Field, ErrorMessage, FieldArray } from 'formik';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import InputMask from 'react-input-mask';

const LinkedUserFormComponent = ({ push, remove, form }) => {
  return (
    <>
      {form.values.linked_users.map((linked_user, index) => (
        <div key={index} className='card p-3 mt-3'>
          {index > 0 && (
            <Row>
              <Col>
                <h5>{`User Linking (${index + 1})`}</h5>
              </Col>
              <Col>
                <Button className='ml-3 close' onClick={() => remove(index)}>
                  <span style={{ color: 'red' }}>&times;</span>
                </Button>
              </Col>
            </Row>
          )}
          <Form.Group controlId={`linked_users.${index}.user_id`}>
            <Form.Label>User ID</Form.Label>
            <Field
              name={`linked_users.${index}.user_id`}
              className='form-control'
            />
            <div className='validation-error'>
              <ErrorMessage name={`linked_users.${index}.user_id`} />
            </div>
          </Form.Group>
          <Form.Group controlId={`linked_users.${index}.linked_agency_code`}>
            <Form.Label>Agency Code</Form.Label>
            <Field
              name={`linked_users.${index}.linked_agency_code`}
              className='form-control'
            />
            <div className='validation-error'>
              <ErrorMessage name={`linked_users.${index}.linked_agency_code`} />
            </div>
          </Form.Group>
          <Form.Group
            controlId={`linked_users.${index}.linked_sub_producer_code`}>
            <Form.Label>Sub-Producer Code</Form.Label>
            <Field
              name={`linked_users.${index}.linked_sub_producer_code`}
              className='form-control'
            />
            <div className='validation-error'>
              <ErrorMessage
                name={`linked_users.${index}.linked_sub_producer_code`}
              />
            </div>
          </Form.Group>
          <div>
            <FieldArray
              id={`linked_users.${index}.company_numbers`}
              name={`linked_users.${index}.company_numbers`}
              render={arrayHelpers => {
                return (
                  <div>
                    {arrayHelpers.form.values.linked_users[
                      index
                    ].company_numbers.map((p, i) => (
                      <div key={i}>
                        <Row>
                          <Col>
                            <Form.Group
                              controlId={
                                form.values.linked_users[index].company_numbers
                              }>
                              <Form.Label>Company Code</Form.Label>
                              <InputMask
                                name={`linked_users[${index}].company_numbers[${i}].linked_no`}
                                value={
                                  arrayHelpers.form.values.linked_users[index]
                                    .company_numbers[i].linked_no
                                }
                                onChange={e =>
                                  arrayHelpers.form.setFieldValue(
                                    `linked_users[${index}].company_numbers[${i}].linked_no`,
                                    e.currentTarget.value
                                  )
                                }
                                onBlur={arrayHelpers.form.onBlur}
                                onError={arrayHelpers.form.onError}
                                className='form-control'
                              />
                              <div className='validation-error'>
                                <ErrorMessage
                                  name={`linked_users[${index}].company_numbers[${i}].linked_no`}
                                />
                              </div>
                            </Form.Group>
                          </Col>
                          <div className='align-self-center'>
                              <Button
                                  className='mr-3 close'
                                  onClick={() => arrayHelpers.remove(i)}>
                                  <span style={{ color: 'red' }}>
                                    &times;
                                  </span>
                                </Button>
                              </div>
                        </Row>
                      </div>
                    ))}
                    <div>
                      <Button
                        variant='link'
                        onClick={() => arrayHelpers.push({ linked_no: '' })}>
                        + Add Linked Code
                      </Button>
                    </div>
                  </div>
                );
              }}
            />
          </div>
        </div>
      ))}
      <div className='d-flex justify-content-end mt-3'>
        <Button
          variant='link'
          name='add_linked_numbers'
          onClick={() =>
            push({
              user_id: '',
              linked_agency_code: '',
              linked_sub_producer_code: '',
              company_numbers: [{ linked_no: '' }]
            })
          }>
          + Add Additional Agency
        </Button>
      </div>
    </>
  );
};

export default LinkedUserFormComponent;
